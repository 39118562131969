import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const QuestionEN1 = () => {

    return (
        <>
            <p className="title">GENERAL ISSUES / <span>How do I create a @swisscows.email account? </span></p>  
            <p><b>Instructions:</b> In order to create a @swisscows.email address you need a new account.</p>
            <p>IMPORTANT! If you already have an account at swisscows.com, you have to log out first.</p>
            <StaticImage className="q-image" quality={100} src="../../images/question1/A01_EN1.png" alt="" />
            
            <p>Then go to the <a href="https://swisscows.email/en/" target="_blank">swisscows.email</a> website and click on the LOGIN window with the red envelope. </p>
            <StaticImage className="q-image" quality={100} src="../../images/question1/A01_EN2.png" alt="" />
        
        </>
    );
}

export default QuestionEN1;